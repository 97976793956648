import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import Headline from '@objects/headline'
import Icon from '@objects/icon'
import { TeaserCopy } from '@objects/copy'

const StyledSinglePdfWrapper = styled.div.attrs({
  className: 'py-6',
})`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors['gray-light']?.default}`};
`

const StyledDownloadIcon = styled(Icon).attrs({
  name: 'Download',
  className: 'text-xl text-red',
})`
  margin-left: 4px;
  margin-bottom: 4px;
`

function SinglePdf({
  className,
  title,
  description,
  fileurl,
  size,
  updatedAt,
}) {
  const regex = /[0-9a-z]+$/i
  const extension = fileurl?.toUpperCase().match(regex)

  const { formatDate } = useDateFns()
  return (
    <StyledSinglePdfWrapper>
      {title && (
        <Headline
          level={2}
          className="mb-2 text-lg font-medium leading-4"
          ariaLabel={title}
        >
          {title}
        </Headline>
      )}
      {description && <TeaserCopy className="mb-2" html={description} />}
      <div className="flex flex-row justify-between items-center">
        <p className="font-normal text-sm text-black-650">
          <FormattedMessage id="downloads.uploaded.at" />{' '}
          {formatDate(updatedAt)}
        </p>
        <a
          className="cursor-pointer flex flex-row justify-center items-center font-medium text-red"
          target="_blank"
          rel="noreferrer"
          href={fileurl}
        >
          {`${extension} (${size}MB)`}

          <StyledDownloadIcon />
        </a>
      </div>
    </StyledSinglePdfWrapper>
  )
}

SinglePdf.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fileurl: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
}

export default SinglePdf
